class ProcessingFile {
  constructor(fileName) {
    this.fileName = fileName
    this.createdAt = Date.now()
  }

  isTimeout() {
    return (Date.now() - this.createdAt) > 50000;
  }
}


export default ProcessingFile
