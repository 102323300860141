import Noty from 'noty'

import s3Bucket from './../../services/s3-bucket'
import {
  getFileFromPath,
  getPathWithPrefixExtension,
  getPathWithoutFileFromPath,
} from './../../util/index'
import ProcessingFile from './processing-file'

const audioExtensionsRegex = /\.wav$|\.aiff$|\.aif$|\.ogg$/
const audioExtraExtensionsRegex = /\.mp3$|\.flac$|\.aac$|\.alac$|\.m4a$|\.wma$/


class ProcessedFilesWatcher {
  static fileManagerComponentActions = undefined
  static filesWatching = []
  static filesProcessed = []
  static filesTimeout = []

  static setFileManagerActions = (actions) => {
    ProcessedFilesWatcher.fileManagerComponentActions = actions
  }

  static isFileBeingWatched(fileId) {
    const vrxFile = getPathWithPrefixExtension(fileId, '.vrx')
    const stemFile = getPathWithPrefixExtension(fileId, '.stem')

    // it may be one of the audioExtraExtensionsRegex - which adds wav
    const vrxFileWav = getPathWithPrefixExtension(fileId, '.vrx') + '.wav'
    const stemFileWav = getPathWithPrefixExtension(fileId, '.stem') + '.wav'

    return ProcessedFilesWatcher.filesWatching.find(
      iteratedProcessingFile => iteratedProcessingFile.fileName === vrxFile ||
        iteratedProcessingFile.fileName === stemFile ||
        iteratedProcessingFile.fileName === vrxFileWav ||
        iteratedProcessingFile.fileName === stemFileWav
    )
  }

  static checkIfProcessedInterval = setInterval(() => {
    ProcessedFilesWatcher.checkIfProcessed()
  }, 1000)

  static showNotificationInterval = setInterval(() => {
    ProcessedFilesWatcher.showProcessedNotification()
    ProcessedFilesWatcher.showTimeoutNotification()
  }, 2100)

  static filesWatchingContainFileName = (fileName) => {
    return ProcessedFilesWatcher.filesWatching.find(processingFile => processingFile.fileName === fileName)
  }

  static filesProcessedContainFileName = (fileName) => {
    return ProcessedFilesWatcher.filesProcessed.find(processingFile => processingFile.fileName === fileName)
  }

  static checkIfProcessed = () => {
    if (ProcessedFilesWatcher.filesWatching.length === 0) {
      return
    }
  
    ProcessedFilesWatcher.filesWatching.forEach((processingFile) => {
      const fileName = processingFile.fileName;

      if (processingFile.isTimeout()) {
        ProcessedFilesWatcher.filesTimeout.push(processingFile)
        ProcessedFilesWatcher.filesWatching = ProcessedFilesWatcher.filesWatching
          .filter((processingFileIteration) => processingFileIteration.fileName !== fileName)

        ProcessedFilesWatcher.refreshFileManagerDirectory(fileName)
        return
      }

      if (processingFile.isCheckingAvailability) {
        return;
      }

      s3Bucket.s3.headObject({ Key: fileName }).promise()
      .then(() => {
        processingFile.isCheckingAvailability = false;

        if (ProcessedFilesWatcher.filesWatchingContainFileName(fileName) && !ProcessedFilesWatcher.filesProcessedContainFileName(fileName)) {
          ProcessedFilesWatcher.filesProcessed.push(processingFile)
          ProcessedFilesWatcher.filesWatching = ProcessedFilesWatcher.filesWatching
            .filter((processingFileIteration) => processingFileIteration.fileName !== fileName)

          ProcessedFilesWatcher.refreshFileManagerDirectory(fileName)
        }
      }).catch(() => { processingFile.isCheckingIfAvailable = false; })

      processingFile.isCheckingAvailability = true;
    })
  }

  static showProcessedNotification = () => {
    if (ProcessedFilesWatcher.filesProcessed.length === 0) {
      return
    }

    const notification = new Noty({
      type: 'success',
      timeout: 3000,
      closeWith: ['click', 'button'],
      theme: 'bootstrap-v4',
      text: `
        <div>
          New processed file${ProcessedFilesWatcher.filesProcessed.length === 1 ? '' : 's'}:
          <ul>
            ${
              ProcessedFilesWatcher.filesProcessed
                .map(file => `<li>- <b>${getFileFromPath(file.fileName)}</b></li>`).join('')
            }
          </ul>
        </div>`,
    }).show()

    ProcessedFilesWatcher.filesProcessed = []

    return notification
  }

  static showTimeoutNotification = () => {
    if (ProcessedFilesWatcher.filesTimeout.length === 0) {
      return
    }

    const notification = new Noty({
      type: 'error',
      timeout: 3000,
      closeWith: ['click', 'button'],
      theme: 'bootstrap-v4',
      text: `
        <div>
          Failed file${ProcessedFilesWatcher.filesTimeout.length === 1 ? '' : 's'}:
          <ul>
            ${
              ProcessedFilesWatcher.filesTimeout.map(file => `<li>- <b>${getFileFromPath(file.fileName)}</b></li>`)
                .join('')
            }
          </ul>
        </div>`,
    }).show()

    ProcessedFilesWatcher.filesTimeout = []

    return notification
  }

  static addFileWatcher(fileId) {
    const vrxProcessingFile = new ProcessingFile(getPathWithPrefixExtension(fileId, '.vrx'))
    const stemProcessingFile = new ProcessingFile(getPathWithPrefixExtension(fileId, '.stem'))
    const vrxWavProcessingFile = new ProcessingFile(fileId + '.vrx.wav')
    const stemWavProcessingFile = new ProcessingFile(fileId + '.stem.wav')

    if (audioExtensionsRegex.test(fileId)) {
      ProcessedFilesWatcher.filesWatching.push(vrxProcessingFile)
      ProcessedFilesWatcher.filesWatching.push(stemProcessingFile)
    } else if (audioExtraExtensionsRegex.test(fileId)) {
      ProcessedFilesWatcher.filesWatching.push(vrxWavProcessingFile)
      ProcessedFilesWatcher.filesWatching.push(stemWavProcessingFile)
    } else {
      ProcessedFilesWatcher.filesWatching.push(vrxProcessingFile)
    }
  }

  static getCurrentResourceId() {
    return ProcessedFilesWatcher.fileManagerComponentActions.getResource().id
  }

  static refreshFileManagerDirectory(key) {
    if (getPathWithoutFileFromPath(key) !== ProcessedFilesWatcher.getCurrentResourceId()) {
      return
    }

    ProcessedFilesWatcher.fileManagerComponentActions.navigateToDir(ProcessedFilesWatcher.getCurrentResourceId(), null, false)
  }
}

window.ProcessedFilesWatcher = ProcessedFilesWatcher


export default ProcessedFilesWatcher
