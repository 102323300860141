import React, { PureComponent } from 'react'
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager'

import s3Connector, { setOnUploadEvent } from './s3-connector'

import s3Bucket from './../../services/s3-bucket'
import processedFilesWatcher from './../../service-workers/processed-files-watcher'

setOnUploadEvent((file, resourceFolder) => {
  console.log('setOnUploadEvent', file, resourceFolder, `${resourceFolder.id}${file.name}`)

  processedFilesWatcher.addFileWatcher(`${resourceFolder.id}${file.name}`)
})


const apiOptions = {
  ...s3Connector.apiOptions,
}

class FManager extends PureComponent {
  render() {
    return (
      <div>
        <div
          style={{ 
            height: '100vh',
            minWidth: '320px',
            flex: '1',
            padding: '12px',
            backgroundColor: '#f5f5f5'
        }}>
          <FileManager>
            <FileNavigator
              id="filemanager-1"
              api={s3Connector.api}
              apiOptions={{
                ...apiOptions,
                apiRoot: s3Bucket.userPath,
              }}
              capabilities={s3Connector.capabilities}
              listViewLayout={s3Connector.listViewLayout}
              viewLayoutOptions={s3Connector.viewLayoutOptions}
              onResourceLocationChange={
                resourceLocation => console.log('onResourceLocationChange', resourceLocation)
              }
            />
          </FileManager>
        </div>
      </div>
    )
  }
}

export default FManager
