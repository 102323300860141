async function readLocalFile() {
  return new Promise((resolve, reject) => {
    const uploadInput = document.createElement("input");

    document.body.onfocus = () => {
      try {
        document.body.removeChild(uploadInput)
      } catch (e) {
        
      }
    }

    uploadInput.addEventListener('change', _ => {
      const files = [
        ...uploadInput.files,
      ]

      resolve(
        files.map(file => ({
          type: file.type,
          name: file.name,
          file
        }))
      )
    });

    // This input element in IE11 becomes visible after it is added on the page
    // Hide an input element
    uploadInput.style.visibility = 'hidden';

    uploadInput.type = "file";
    uploadInput.multiple = true;
    document.body.appendChild(uploadInput);
    uploadInput.click();
  });
}

export {
  readLocalFile
}
