import React, { Component } from 'react'
import { withAuthenticator } from 'aws-amplify-react'

import './services/amplify'
import s3Bucket from './services/s3-bucket'

import FileManager from './ui/FileManager/index'

import './App.scss'

class App extends Component {
  state = {
    s3Auth: false,
  }

  componentDidMount() {
    this.setS3Auth()
  }

  setS3Auth() {
    s3Bucket.setS3Credentials().then(() => {
      this.setState({
        s3Auth: true,
      })
    })
  }

  render() {
    return (
      <div className="App">
        {this.state.s3Auth &&
          <FileManager />
        }
      </div>
    )
  }
}

export default withAuthenticator(App)
