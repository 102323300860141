import createFolder from './create-folder';
import deleteResource from './delete-resource';
import download from './download';
import upload, { setOnUploadEvent as uploadSetOnUploadEvent } from './upload';
import rename from './rename';
import sort from './sort';

import processedFilesWatcher from './../../../../service-workers/processed-files-watcher'

// workaround - unfortunately, there is no other way to access to this actions
function setProcessedFilesWatcherActions(actions) {
  processedFilesWatcher.setFileManagerActions(actions)
}

const capabilities = [
  createFolder,
  rename,
  download,
  upload,
  deleteResource,
  sort,
];

/**
 * Actions' fields list:
 *  showDialog,
 *  hideDialog,
 *  navigateToDir,
 *  updateNotifications,
 *  getSelection,
 *  getSelectedResources,
 *  getResource,
 *  getResourceChildren,
 *  getResourceLocation,
 *  getNotifications,
 *  getSortState
 *
 *  Called from FileNavigator (componentDidMount() and componentWillReceiveProps())
 *
 * @param apiOptions
 * @param {object} actions
 * @returns {array}
 */
export default (apiOptions, actions) => {
  // workaround - unfortunately, there is no other way to access to this actions
  setProcessedFilesWatcherActions(actions)

  return capabilities.map(capability => capability(apiOptions, actions))
}

export const setOnUploadEvent = uploadSetOnUploadEvent
