import AWS from 'aws-sdk'
import Amplify from 'aws-amplify'

window.AWS = AWS

const Bucket = process.env.REACT_APP_BUCKET_NAME

const s3 = window.s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {Bucket},
})

const s3Bucket = {
  setS3Credentials() {
    return new Promise((resolve, reject) => {
      Amplify.Auth.currentUserCredentials().then((credentials) => {
        s3.config.update({ credentials })
        this.identityId = credentials.data.IdentityId
        this.userPath = `${this.preUserPath}${credentials.data.IdentityId}/`

        resolve()
      })
    })
  },

  s3,
  Bucket,

  preUserPath: 'home/',
  identityId: undefined,
  userPath: undefined,
}

export default s3Bucket
