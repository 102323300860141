import request from 'superagent';
import s3Bucket from './../../../services/s3-bucket'

const capabilities = {
  canAddChildren: true,
  canCopy: true,
  canDelete: true,
  canDownload: true,
  canEdit: true,
  canListChildren: true,
  canRemoveChildren: true,
  canRename: true,  
}
const folderCapabilities = {
  ...capabilities,
  canRename: false,
}

function normalizeResource({
  ETag,
  Key,
  Prefix,
  LastModified,
  Owner,
  Size,
  StorageClass,
}) {
  const name = getFileFromPath((Key || Prefix).replace(/\/$/, ''))

  return {
    createdTime: Date.parse(LastModified),
    id: Key || Prefix,
    modifiedTime: Date.parse(LastModified),
    name,
    type: Size ? 'file' : 'dir',
    // type: 'dir',
    size: Size ? parseInt(Size, 10) : undefined,
    // parents: [],
    capabilities: {
      ...capabilities,
      canRename: Size ? true : false,
    },
  };
}

function hasSignedIn() {
  return true;
}

function init() {
  return {
    apiInitialized: true,
    apiSignedIn: true
  };
}

function getFileFromPath(path) {
  return path.replace(/\/$/, '').replace(/(.*)\//, '')
}

async function getCapabilitiesForResource(options, resource) {
  console.log('getCapabilitiesForResource')
  return resource.capabilities || [];
}

async function getResourceById(options, id) {
  console.log('~getResourceById', options, id)
  if (typeof id === 'undefined' || id === '' || id === '/' || id === s3Bucket.preUserPath) {
    return {
      id: options.apiRoot,
      ancestors: [],
      name: getFileFromPath(options.apiRoot),
      type: 'dir',
      capabilities: folderCapabilities,
    }
  }

  let ancestors = id.split('/')
    .filter(folderName => folderName !== '')

  ancestors = ancestors.map((id, idIndex) => {
    const prevPath = ancestors.slice(0, idIndex).join('/')
    return {
      id: `${prevPath === '' ? '' : `${prevPath}/`}${id}/`,
      name: getFileFromPath(id),
    }
  })

  ancestors = ancestors.filter(folderName => folderName.id !== id)

  return {
    id: `${id}`,
    ancestors,
    parentId: options.apiRoot,
    name: getFileFromPath(id),
    type: 'dir',
    capabilities: folderCapabilities,
  }
}

function sortByName(results, sortDirection) {
  return results.sort((a, b) => {
    var nameA = a.name.toUpperCase()
    var nameB = b.name.toUpperCase()

    if (sortDirection === 'ASC') {
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
    } else {
      if (nameA > nameB) {
        return -1
      }
      if (nameA < nameB) {
        return 1
      }
    }

    return 0
  });
}

function sortByModifiedTime(results, sortDirection) {
  if (sortDirection === 'ASC') {
    return results.sort((a, b) => a.modifiedTime - b.modifiedTime)
  } else {
    return results.sort((a, b) => b.modifiedTime - a.modifiedTime)
  }
}

function sortContent(results, sortBy, sortDirection) {
  if (sortBy === 'name') {
    return sortByName(results, sortDirection)
  } else {
    return sortByModifiedTime(results, sortDirection)
  }
}

async function getChildrenForId(options, { id, sortBy = 'name', sortDirection = 'ASC' }) {
  console.log('~getChildrenForId', options, sortBy, sortDirection, `--${id}--`, id === '/')
  let resources
  if (id && id !== '/') {
    resources = await s3Bucket.s3.listObjectsV2({ Prefix: id, Delimiter: '/' }).promise()
  } else {
    resources = await s3Bucket.s3.listObjectsV2({ Delimiter: '/' }).promise()
  }

  const resultsFiles = resources.Contents.filter((object) => object.Key !== id).map(content => normalizeResource({ ...content }))
  const resultsFolders = resources.CommonPrefixes.map(content => normalizeResource({ ...content }))

  const results = [
    //s3 api returns the current folder, we filter it
    ...sortContent(resultsFolders, sortBy, sortDirection),
    ...sortContent(resultsFiles, sortBy, sortDirection),
  ]

  console.log('resources', resources)
  return results
}

async function getParentsForId(options, id, result = []) {
  console.log('getParentsForId', options, id)
  if (!id) {
    return result;
  }

  const resource = await getResourceById(options, id);
  if (resource && resource.ancestors) {
    return resource.ancestors;
  }
  return result;
}

async function getBaseResource(options) {
  console.log('get abse res')
  const route = `${options.apiRoot}/files`;
  const response = await request.get(route);
  return normalizeResource(response.body);
}

async function getIdForPartPath(options, currId, pathArr) {
  console.log('get id for part path')
  const resourceChildren = await getChildrenForId(options, { id: currId });
  for (let i = 0; i < resourceChildren.length; i++) {
    const resource = resourceChildren[i];
    if (resource.name === pathArr[0]) {
      if (pathArr.length === 1) {
        return resource.id;
      } else {
        return getIdForPartPath(options, resource.id, pathArr.slice(1));
      }
    }
  }

  return null;
}

async function getIdForPath(options, path) {
  console.log('getIdForPath')
  const resource = await getBaseResource(options);
  const pathArr = path.split('/');

  if (pathArr.length === 0 || pathArr.length === 1 || pathArr[0] !== '') {
    return null;
  }

  if (pathArr.length === 2 && pathArr[1] === '') {
    return resource.id;
  }

  return getIdForPartPath(options, resource.id, pathArr.slice(1));
}

async function getParentIdForResource(options, resource) {
  console.log('getParentIdForResource')
  return resource.parentId;
}

async function uploadFileToId({ apiOptions, parentId, file, notificationChildId, onProgress }) {
  console.log('upload file to id', apiOptions, parentId, file)

  return new Promise((resolve, reject) => {
    const uploadRequest = s3Bucket.s3.upload({
      Key: `${parentId}${file.name}`.replace(/^\//, ''),
      Body: file.file,
    }, (err, data) => {
      if (err) {
        reject(err)
      }

      resolve(data)
    })

    uploadRequest.on('httpUploadProgress', function(data){
      onProgress((data.loaded / data.total) * 100, notificationChildId)
    });
  })
}

async function downloadResource(id) {
  return s3Bucket.s3.getSignedUrl('getObject', {
    Bucket: s3Bucket.Bucket,
    Key: id,
    Expires: 1000
   })
}

async function downloadResources({ apiOptions, resources, onProgress }) {
  console.log('dwn ress', apiOptions, resources, onProgress)
}

async function createFolder(options, parentId, folderName) {
  console.log('createFolder', options, parentId, folderName)
  return await s3Bucket.s3.upload({
    Body: '',
    Key: `${parentId}${folderName}/`.replace(/^\//, ''),
  }).promise()
}

function getResourceName(apiOptions, resource) {
  return resource.name;
}

async function renameResource(options, id, newName) {
  console.log('renameResource', id, newName, {
    Bucket: s3Bucket.Bucket,
    CopySource: `${s3Bucket.Bucket}/${id}`,
    Key: newName,
  })

  const oldName = getFileFromPath(id.replace(/\/$/, ''))
  const path = id.replace(oldName, '')

  await s3Bucket.s3.copyObject({
    Bucket: s3Bucket.Bucket,
    CopySource: `${s3Bucket.Bucket}/${id}`,
    Key: path + newName,
  }).promise()
  await s3Bucket.s3.deleteObject({
    Key: id,
  }).promise()
}

async function removeResource(options, resource) {
  // we need to delete the content/files before the folder
  // a folder can be deleted just when it's empty
  if (resource.type === 'dir') {
    const filesInThatPath = await s3Bucket.s3.listObjectsV2({ Prefix: resource.id }).promise()

    if (filesInThatPath.Contents.length) {
      await s3Bucket.s3.deleteObjects({
        Delete: {
          Objects: filesInThatPath.Contents.map(content => ({ Key: content.Key })),
        }
      }).promise()
    }
  }

  await s3Bucket.s3.deleteObject({
    Key: resource.id,
  }).promise()
}

async function removeResources(options, selectedResources) {
  console.log('removeResources')
  return Promise.all(selectedResources.map(resource => removeResource(options, resource)))
}

export default {
  init,
  hasSignedIn,
  getIdForPath,
  getResourceById,
  getCapabilitiesForResource,
  getChildrenForId,
  getParentsForId,
  getParentIdForResource,
  getResourceName,
  createFolder,
  downloadResource,
  downloadResources,
  renameResource,
  removeResources,
  uploadFileToId
};