import api from '../api';
import notifUtils from '../utils/notifications';
import { getIcon } from '../icons';
import nanoid from 'nanoid';
import onFailError from '../utils/onFailError';
import { readLocalFile } from '../utils/upload';
import icons from '../icons-svg';
import getMess from '../translations';

const label = 'upload';

let onUploadEvent

export const setOnUploadEvent = (callback) => {
  onUploadEvent = callback
}

async function handler(apiOptions, actions) {
  const {
    navigateToDir,
    updateNotifications,
    getResource,
    getNotifications
  } = actions;

  const getMessage = getMess.bind(null, apiOptions.locale);

  const notificationId = label;

  const onStart = ({ name, size }, notificationChildId) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const childElement = {
      elementType: 'NotificationProgressItem',
      elementProps: {
        title: name,
        progress: 0,
        icon: getIcon({ name })
      }
    };

    const newChildren =
      notifUtils.addChild((notification && notification.children) || [], notificationChildId, childElement);
    const newNotification = {
      title: newChildren.length > 1 ?
        getMessage('uploadingItems', { quantity: newChildren.length }) :
        getMessage('uploadingItem'),
      children: newChildren
    };

    const newNotifications = notification ?
      notifUtils.updateNotification(notifications, notificationId, newNotification) :
      notifUtils.addNotification(notifications, notificationId, newNotification);

    updateNotifications(newNotifications);
  };

  const onProgress = (progress, notificationChildId) => {
    const notifications = getNotifications();
    const notification = notifUtils.getNotification(notifications, notificationId);
    const child = notifUtils.getChild(notification.children, notificationChildId);
    const newChild = {
      ...child,
      element: {
        ...child.element,
        elementProps: {
          ...child.element.elementProps,
          progress
        }
      }
    };
    const newChildren = notifUtils.updateChild(notification.children, notificationChildId, newChild);
    const newNotifications = notifUtils.updateNotification(notifications, notificationId, { children: newChildren });
    updateNotifications(newNotifications);
  };

  const handleFile = async file => {
    const notificationChildId = nanoid()

    try {
      onStart({ name: file.name, size: file.file.size }, notificationChildId);
      await api.uploadFileToId({ apiOptions, parentId: resource.id, file, onProgress, notificationChildId });
      const notifications = getNotifications();
      const notification = notifUtils.getNotification(notifications, notificationId);
      const notificationChildrenCount = notification.children.length;
      let newNotifications;
      if (notificationChildrenCount > 1) {
        newNotifications = notifUtils.updateNotification(
          notifications,
          notificationId, {
            children: notifUtils.removeChild(notification.children, notificationChildId)
          }
        );
      } else {
        newNotifications = notifUtils.removeNotification(notifications, notificationId);
      }
      updateNotifications(newNotifications);
      if (getResource().id === resource.id) {
        navigateToDir(resource.id, null, false);
      }
  
      if (typeof onUploadEvent === 'function') {
        onUploadEvent(file, resource)
      }
    } catch (err) {
      onFailError({
        getNotifications,
        label: getMessage(label),
        notificationId,
        updateNotifications
      });
      console.log(err)
    }
  };

  const resource = getResource();
  const files = await readLocalFile(true);

  files.forEach(file => {
    handleFile(file)
  });
}

export default (apiOptions, actions) => {
  const localeLabel = getMess(apiOptions.locale, label)
  const { getResource } = actions

  return {
    id: label,
    icon: { svg: icons.fileUpload },
    label: localeLabel,
    shouldBeAvailable: (apiOptions) => {
      const resource = getResource();
      if (!resource || !resource.capabilities) {
        return false
      }
      return resource.capabilities.canAddChildren
    },
    availableInContexts: ['files-view', 'new-button'],
    handler: () => handler(apiOptions, actions)
  };
}
