export const getFileFromPath = (path) => {
  return path.replace(/\/$/, '').replace(/(.*)\//, '')
}

export const getExtensionFromPath = (path) => {
  return path.match(/(?:\.([^.]+))?$/)[0]
}

export const getFileNameFromPath = (path) => {
  return path.replace(/\/$/, '').replace(/(.*)\//, '').replace(getExtensionFromPath(path), '')
}

export const getPathWithoutFileFromPath = (path) => {
  return path.replace(getFileFromPath(path), '')
}

export const getPathWithPrefixExtension = (path, preFixExtensionWithDot) => {
  return `${getPathWithoutFileFromPath(path)}` +
  `${getFileNameFromPath(path)}${preFixExtensionWithDot}${getExtensionFromPath(path)}`
}
