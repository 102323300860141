import api from './api';
import apiOptions from './apiOptions';
import capabilities, { setOnUploadEvent as uploadSetOnUploadEvent } from './capabilities';
import listViewLayout from './list-view-layout';
import viewLayoutOptions from './view-layout-options';

// setOnUploadEvent((file, resourceFolder) => {
//   console.log('hey there...', file, resourceFolder, `${resourceFolder.id}${file.name}`)
// })

export default {
  api,
  apiOptions,
  capabilities,
  listViewLayout,
  viewLayoutOptions
}

export const setOnUploadEvent = uploadSetOnUploadEvent