import api from '../api';
import { promptToSaveBlob } from '../utils/download';
import onFailError from '../utils/onFailError';
import icons from '../icons-svg';
import getMess from '../translations';

const label = 'download';

async function handler(apiOptions, actions) {
  const {
    updateNotifications,
    getSelectedResources,
    getNotifications
  } = actions;

  const getMessage = getMess.bind(null, apiOptions.locale);

  const notificationId = label;

  const download = function (resources) {
    resources.forEach(resource => {
      const { id, name } = resource;
      api.getResourceById(apiOptions, id).then(() => {
        api.downloadResource(id).then((url) => {
          promptToSaveBlob({ name, downloadUrl: url });
        })
      })
    });
  }

  try {
    const resources = getSelectedResources();

    download(resources)
  } catch (err) {
    onFailError({
      getNotifications,
      label: getMessage(label),
      notificationId,
      updateNotifications
    });
    console.log(err)
  }
}

export default (apiOptions, actions) => {
  const localeLabel = getMess(apiOptions.locale, label);
  const { getSelectedResources } = actions;
  return {
    id: label,
    icon: { svg: icons.fileDownload },
    label: localeLabel,
    shouldBeAvailable: (apiOptions) => {
      const selectedResources = getSelectedResources();

      return (
        selectedResources.length > 0 &&
        !selectedResources.some(r => r.type === 'dir') &&
        selectedResources.every(r => r.capabilities.canDownload)
      );
    },
    availableInContexts: ['row', 'toolbar'],
    handler: () => handler(apiOptions, actions)
  };
}
