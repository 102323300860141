import FileSaver from 'file-saver'
import download from 'downloadjs'

console.log('download', download)

// a case when we need to silently download a file using Javascript, and prompt to save it afterwards
function promptToSaveBlob({ content, name, downloadUrl }) {
  if (downloadUrl) {
    console.log('name', name)

    var x=new XMLHttpRequest();
    x.open( "GET", downloadUrl , true)
    x.responseType="blob"
    x.onload= function(e){download(e.target.response, name)}
    x.send()

    // let a = document.createElement('a')
    // a.href = downloadUrl
    // a.target = '_blank'
    // a.download = 'test.mp3'
    // document.body.appendChild(a)
    // a.click()
  } else {
    const blob = new Blob([content], { type: 'octet/stream' });
    FileSaver.saveAs(blob, name);
  }
}

// a case when we trigger a direct download in browser
// used in google drive' connector
function triggerHiddenForm({ downloadUrl, target = '_self' }) {
  const form = document.createElement("form");
  form.action = downloadUrl;
  form.target = target;
  form.method = 'GET';

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export {
  promptToSaveBlob,
  triggerHiddenForm
};
